@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.shortsContainer {
  background-color: var(--shorts-bg);
  padding: 16px;
  min-height: 360px;
  border-radius: 12px;
  margin-bottom: 15px;
  position: relative;

  .wrap {
    border-radius: 14px;
    aspect-ratio: 9 / 16;

    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      background: linear-gradient(180deg, transparent, #000);
      z-index: 2;
      width: 100%;
      height: 160px;
    }
  }

  .overlayContent {
    width: calc(100% - 5px);
    z-index: 5;
    padding: 5px 10px;

    .overlayDescription {
      max-height: 45vh;
      overflow: auto;
      p {
        color: #fff;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }

  .overLay {
    background: linear-gradient(0deg, transparent 0%, #000000 40%);
    height: 160px;
    transition-duration: 1s;
  }

  .reelImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    // object-fit: contain;
    object-position: center;
  }

  .reeltwitter {
    margin-top: 10px;
    padding: 15px 10px;
    background: #23272e;
    border-radius: 10px;
  }

  .twitterContent p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    overflow: hidden;
  }
  .articleImg {
    aspect-ratio: 16 / 10;
    object-fit: cover;
    // border-radius: 10px;
  }

  .twitterImagesGrid {
    border-radius: 10px;
    overflow: hidden;
    display: grid;
    gap: 2px; // Adjust the gap between images as needed

    &.image-1 {
      grid-template-columns: 1fr;

      .twitterImage {
        height: 300px !important;
        object-fit: cover;
      }
    }

    &.images-2 {
      grid-template-columns: 1fr 1fr;

      .twitterImage {
        height: 200px !important;
        object-fit: cover;
      }
    }

    &.images-3 {
      grid-template-columns: auto auto;

      .twitterImage:nth-child(1) {
        grid-area: span 2 / 1 / 1;
        height: 100% !important;
        object-fit: cover;
      }
      .twitterImage {
        height: 120px !important;
        object-fit: cover;
      }
    }

    &.images-4 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;

      .twitterImage {
        height: 120px !important;
        object-fit: cover;
      }
    }
  }

  .twitterVideo {
    padding-top: 20px;
    overflow: hidden;

    video {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .twitterBtn {
    display: inline-block;
    width: 30px;
    height: 30px;
    padding: 5px;
  }
}

.arrow {
  width: 24px;
  height: 44px;
  font-size: 0;
  z-index: 5;
  top: 60%;
  border-radius: 13px;
  border: 2px solid $theme-color;

  &::before {
    content: '';
    margin: auto;
    width: 100%;
    height: 100%;
    display: block;
    background: url(../../../assets/images/icon/right-theme-arrow.svg) no-repeat center center / 100% auto;
  }
  &.prev::before {
    @include transform(rotate(180deg));
  }
  &:active {
    background: $theme-color;
    &::before {
      @include filter(brightness(0) invert(1));
    }
  }
  &:disabled {
    display: none;
  }
}

@media (max-width: 1120px) {
  .shortsContainer {
    min-height: 320px;
  }
}

@media (max-width: 767px) {
  .shortsContainer {
    min-height: 300px;
    .arrow {
      display: none;
    }
  }
}



@media (max-width: 400px) {
  .shortsContainer {
    min-height: 250px;
  }
}
