// WORD CUP

// @import '@assets/scss/variables.scss';
// @import '@assets/scss/mixins.scss';

// .statPlayer{
//     cursor: pointer;
//     border-bottom: 1px solid #271B5B;
//     background-image: linear-gradient(to left, #263997, #00a2e3);
//     // background: linear-gradient(90deg, rgba(50,10,115,1) 50%, rgba(0,150,210,1) 100%);
//     // background: url(../../../assets/images/icon/player-icc2-bg.svg) no-repeat left top / auto 200%;
//     &::before {
//         @extend %after-before;
//         height: 110%;
//         aspect-ratio: 1;
//         background: url(../../../assets/images/icon/wc-player-bg.svg) no-repeat center center / cover;
//         top: 50%;
//         left: 0;
//         transform: translateY(-50%);
//       }
//     &:last-child{
//         border: none;
//     }
//     .playerImage{
//         position: relative;
//         .icon { width: 84px; }
//         .serialNo{
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 22px;
//             height: 22px;
//             text-align: center;
//             border-radius: 50%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             background-color: var(--theme-color-medium);
//         }
//     }
//     h4 { margin-bottom: -4px; }
//     .playerName { color: $light-color;}
//     .value { color: #FF00A5; }
// }

// /***** Responsive CSS Start ******/

// @media (max-width: 1399px) {

// }

// @media (max-width: 1199px) {

// }

// IPL
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.statPlayer{
    cursor: pointer;
    padding: 10px 0;
    border-bottom: 1px solid var(--border-medium);
    &:last-child{
        border: none;
    }
    .playerImage {
        position: relative;
        .icon { width: 44px; }
        .serialNo{
            position: absolute;
            top: 0;
            left: 0;
            width: 22px;
            height: 22px;
            text-align: center;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--theme-color-medium);
        }
    }
}

/***** Responsive CSS Start ******/

@media (max-width: 1399px) {
}

@media (max-width: 1199px) {
}

