// WORLD CUP

// @import '@assets/scss/variables.scss';
// @import '@assets/scss/mixins.scss';

// .seriesStats {
//   &::after {
//     @extend %after-before;
//     background: url(../../../../assets/images/icon/icc-pattern-triangle.svg) repeat-x center center / auto 100%;
//     height: 10px;
//     width: 100%;
//     bottom: 0px;
//     left: 0;

//     [data-mode="dark"] & {
//       filter: brightness(0) invert(1);
//     }
//   }
// }

// .searchCard {
//   // background: #320a73 url(../../../../assets/images/icon/icc-artwork.png) no-repeat -15% 50% / auto 115%;
//   // background: linear-gradient(90deg, rgba(50,10,115,1) 50%, rgba(0,150,210,1) 100%);
//   box-sizing: border-box;
//   border: none;
//   margin: 0;
//   padding: 6px 16px;
//   color: $light-color;

//   h3,
//   h5 {
//     color: $light-color;
//   }

//   h4 {
//     color: #FF00A5;
//   }
// }

// /***** Responsive CSS Start ******/

// @media (max-width: 767px) {
//   .searchCard { 
//     width: 264px;
//     max-width: calc(100vw - 64px);
//   }
// }


// IPL THEME
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.searchCard { 
  background: var(--light-bg);
  border: 1px solid;
  box-sizing: border-box;
  border: none;
  color: var(--font-color);
  margin: 0;
  padding: 6px 16px;
}

/***** Responsive CSS Start ******/

@media (max-width: 1399px) {

}

@media (max-width: 1199px) {

}
