@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.bannerSection { background: $theme-color; color: $light-color;
  .content { min-height: 40vh; }
}
.ctaSection,
.bannerBottom { background: $font-color; color: $light-color;}
.person { width: 160px; 
  img {
    [data-mode=dark] & {
      filter: brightness(0) invert(1) opacity(0.6);
    }
  }
}
.item { min-height: calc(100% - 8px); }
.list { 
  li { padding-left: 30px; margin-bottom: 12px; position: relative; 
    &::after { @extend %after-before; width: 12px; height: 12px; background: $theme-color; border-radius: 50%; top: 50%; left: 0; @include transform(translateY(-50%)); box-shadow: 0 0 0 6px $theme-medium; }
  }
  h4 { font-weight: 500; }
}
.slide { width: calc(1290px / 8 - 16px); height: 140px;
  > div { width: 100px; }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .slide { width: calc(1116px / 8 - 16px); }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .slide { width: calc(940px / 7 - 16px); }
}

@media (max-width: 991px) {
  .slide { width: calc(740px / 6 - 16px); height: 100px;}
}

@media (max-width: 767px) {
  .slide { width: calc(520px / 5 - 8px); height: 90px;}
}

@media (max-width: 575px) {
  .slide { width: calc(100% / 4 - 8px); height: 90px; max-width: 100px;}
}